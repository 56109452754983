import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from './actions/auth';

import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './assets/styles/custom.scss';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './pages/Home';

import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import RegisterConfirm from './components/Auth/RegisterConfirm';
import Account from './components/Auth/Account';
import Profile from './components/Auth/Profile';
import Store from './components/Auth/Store';
import Favorites from './components/Auth/Favorites';

import Catalog from './pages/Catalog';
import CatalogOwner from './components/Catalog/CatalogOwner';
import CatalogSearch from './components/Catalog/CatalogSearch';
import Categories from './pages/Categories';
import Category from './pages/Category';

import Product from './components/Product/Product';

import Projects from './components/Project/Projects';
import Project from './components/Project/Project';

import Dialogs from './components/Dialog/Dialogs';
import Dialog from './components/Dialog/Dialog';

import Orders from './components/Order/Orders';
import Order from './components/Order/Order';

import Chat from './components/Chat/Chat';

import Subscriptions from './components/Subscription/Subscriptions';

import Stats from './components/Stats/Stats';

import PrivacyPolicy from './pages/Custom/PrivacyPolicy';
import CookiePolicy from './pages/Custom/CookiePolicy';
import TermsOfService from './pages/Custom/TermsOfService';
import ContentUsagePolicy from './pages/Custom/ContentUsagePolicy';

import Contacts from './pages/Custom/Contacts';

import NotFound from './components/Common/NotFound';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch, getUser]);

  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  } 

  return (
    <div className="App">
      <Router>
        <Wrapper>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/confirm/:token" element={<RegisterConfirm />} />
            <Route path="/account" element={<Account />} />
            <Route path="/profile/:slug?" element={<Profile />} />
            <Route path="/store" element={<Store />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/favorites/search" element={<Favorites />} />
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/catalog/owner/:id?" element={<CatalogOwner />} />
            <Route path="/catalog/owner/:id/*" element={<CatalogOwner />} />
            <Route path="/catalog/search" element={<CatalogSearch />} />
            <Route path="/catalog/:part" element={<Categories />} />
            <Route path="/catalog/:part/*" element={<Category />} />
            <Route path="/product/create" element={<Product />} />
            <Route path="/product/:slug?" element={<Product />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/create" element={<Project />} />
            <Route path="/project/:slug?" element={<Project />} />
            <Route path="/dialogs" element={<Dialogs />} />
            <Route path="/dialog/:id" element={<Dialog />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order/create" element={<Order />} />
            <Route path="/order/:slug?" element={<Order />} />
            <Route path="/chat/:region?" element={<Chat />} />
            <Route path="/subscriptions" element={<Subscriptions />} />

            <Route path="/stats" element={<Stats />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/content-usage-policy" element={<ContentUsagePolicy />} />

            <Route path="/contacts" element={<Contacts />} />

            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Wrapper>
      </Router>
    </div>
  );
}

export default App;
