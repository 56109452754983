import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBySlug, changeProfile, saveAvatar, deleteAvatar } from '../../actions/profile';
import {
    firstnameSchema,
    lastnameSchema,
    companySchema,
    locationSchema,
    workingHoursSchema,
    viberSchema,
    whatsappSchema,
    telegramSchema,
    instagramSchema
} from '../../validations/profile';
import { getUserPopularProducts } from '../../actions/product';
import { PHONE_MASK, ALLOWED_IMAGE_TYPES, ERROR_UNSUPPORTED_IMAGE } from '../../constants/auth';
import { API_URL } from '../../constants/api';
import { getDatetimeFormat } from '../../utils/datetime';
import InputMask from 'react-input-mask';
import Breadcrumbs from '../Common/Breadcrumbs';
import DeleteImage from '../Popup/DeleteImage';

import noPhoto from '../../assets/images/profile-no-photo.png';
import arrowWhiteLeft from '../../assets/icons/arrow-white-left.svg';
import pencilWhite from '../../assets/icons/pencil-white.svg';
import trash from '../../assets/icons/trash.svg';
import settingsWhite from '../../assets/icons/settings-white.svg';
import pencil from '../../assets/icons/pencil.svg';
import ok from '../../assets/icons/ok-black.svg';
import ProfileSocials from './ProfileSocials';
import ProductCard from '../Product/ProductCard';
import chevronLeft from '../../assets/icons/chevron-left.svg';
import chevronRight from '../../assets/icons/chevron-right.svg';
import plusWhite from '../../assets/icons/plus-white.svg';

const Profile = () => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const slug = pathArray.length === 3 ? pathArray[2] : null;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const apiError = useSelector((state) => state.profile.error);
    const authUserLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const profileUserLoading = useSelector((state) => state.profile.userLoading);
    const profileUser = useSelector((state) => state.profile.user);
    const userPopularProducts = useSelector((state) => state.product.userPopularProducts);

    const [user, setUser] = useState(null);
    const [isOwn, setIsOwn] = useState(false);
    // const [userSlug, setUserSlug] = useState(slug);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        // console.log(isAuthenticated);
        // console.log(authUserLoading);
        (async () => {
            if (isAuthenticated) {
                if (!slug/* && isAuthenticated*/) {
                    setUser(authUser);
                    setIsOwn(true);
                    // setUserSlug(authUser.slug);
                    setUserId(authUser.id);
                } else if (slug) {
                    const result = await dispatch(getUserBySlug(slug));
                    if (!result) {
                        navigate('/404');
                    }
                    await setUser(profileUser);
                    setIsOwn(false);
                } /*else if (!isAuthenticated && !authUserLoading) {
                    navigate('/');
                }*/
            } else if (!authUserLoading) {
                navigate('/register');
            }
        })();
    }, [slug, isAuthenticated, authUserLoading, authUser, profileUserLoading]);

    useEffect(() => {
        // console.log('profileUser', profileUser)
        if (profileUser) {
            setUserId(profileUser.id);
        }
    }, [profileUser])

    const imageUploadInputRef = useRef();
    const imageEditInputRef = useRef();

    const [activeImage, setActiveImage] = useState({ id: null, path: noPhoto });
    const [images, setImages] = useState([]);

    const [deletePopupOpen, setDeletePopupOpen] = useState(false);

    const [profileData, setProfileData] = useState({
        firstname: '',
        lastname: '',
        company: '',
        location: '',
        workingHours: '',
        createdAt: '',
        dealsCompleted: 0,
        viber: '',
        whatsapp: '',
        telegram: '',
        instagram: '',
    });

    const [editingParam, setEditingParam] = useState(null);

    const [description, setDescription] = useState('');
    const [editingDescription, setEditingDescription] = useState(false);
    const descriptionRef = useRef(null);

    const [rules, setRules] = useState('');
    const [editingRules, setEditingRules] = useState(false);
    const rulesRef = useRef(null);

    const params = {
        firstname: {
            field: { label: 'Ім\'я', name: 'firstname', value: profileData.firstname, ref: useRef(null) },
            validationSchema: firstnameSchema,
            editable: isOwn,
            isEditing: false
        },
        lastname: {
            field: { label: 'Прізвище', name: 'lastname', value: profileData.lastname, ref: useRef(null) },
            validationSchema: lastnameSchema,
            editable: isOwn,
            isEditing: false
        },
        company: {
            field: { label: 'Компанія', name: 'company', value: profileData.company, ref: useRef(null) },
            validationSchema: companySchema,
            editable: isOwn,
            isEditing: false
        },
        location: {
            field: { label: 'Локація', name: 'location', value: profileData.location, ref: useRef(null) },
            validationSchema: locationSchema,
            editable: isOwn,
            isEditing: false
        },
        workingHours: {
            field: { label: 'Робочі години', name: 'workingHours', value: profileData.workingHours, ref: useRef(null) },
            validationSchema: workingHoursSchema,
            editable: isOwn,
            isEditing: false
        },
        createdAt: {
            field: { label: 'На сайті з', name: 'createdAt', value: profileData.createdAt },
            editable: false,
            isEditing: false
        },
        dealsCompleted: {
            field: { label: 'Угод завершено', name: 'dealsCompleted', value: profileData.dealsCompleted },
            editable: false,
            isEditing: false
        },
        viber: {
            field: { label: 'Viber', name: 'viber', mask: PHONE_MASK, value: profileData.viber, ref: useRef(null) },
            // validationSchema: viberSchema,
            editable: isOwn,
            isEditing: false
        },
        whatsapp: {
            field: { label: 'WhatsApp', name: 'whatsapp', mask: PHONE_MASK, value: profileData.whatsapp, ref: useRef(null) },
            stateData: profileData,
            // validationSchema: whatsappSchema,
            editable: isOwn,
            isEditing: false
        },
        telegram: {
            field: { label: 'Telegram', name: 'telegram', value: profileData.telegram, ref: useRef(null) },
            stateData: profileData,
            // validationSchema: telegramSchema,
            editable: isOwn,
            isEditing: false
        },
        instagram: {
            field: { label: 'Instagram', name: 'instagram', value: profileData.instagram, ref: useRef(null) },
            stateData: profileData,
            // validationSchema: instagramSchema,
            editable: isOwn,
            isEditing: false
        },
    }

    useEffect(() => {
        console.log('user', user)
        console.log('profileUserLoading', profileUserLoading)
        if (user) {
            if (user.avatars && Object.keys(user.avatars).length) {
                setActiveImage({ id: user.avatars[0].id, path: getImageUrl(user.avatars[0].path) });
                setImages(Object.values(user.avatars));
            }
            else {
                setActiveImage({ id: null, path: noPhoto });
                setImages([]);
            }

            setProfileData({                
                firstname: user.profile.firstname || '',
                lastname: user.profile.lastname || '',
                company: user.profile.company || '',
                location: user.profile.location || '',
                workingHours: user.profile.workingHours || '',
                createdAt: user.createdAt ? getDatetimeFormat(user.createdAt) : '',
                dealsCompleted: 0,
                viber: user.profile.viber || '',
                whatsapp: user.profile.whatsapp || '',
                telegram: user.profile.telegram || '',
                instagram: user.profile.instagram || '',
            });

            dispatch(getUserPopularProducts(user.id));
            setDescription(user.profile.description || '')
            setRules(user.profile.rules || '')
        }
    }, [user]);

    const [imageError, setImageError] = useState(null);

    const [validationErrors, setValidationErrors] = useState({
        firstname: null,
        lastname: null,
        company: null,
        location: null,
        workingHours: null,
        createdAt: null,
        dealsCompleted: null,
        viber: null,
        whatsapp: null,
        telegram: null,
        instagram: null,
    });

    const [validationError, setValidationError] = useState(null);

    const setError = (name, message) => {
        setValidationErrors({
            ...validationErrors,
            [name]: message,
        });
        setValidationError(message);
        return false;
    }

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else return `${API_URL}/${path}`;
    }

    const checkImageType = (imageType) => {
        if (ALLOWED_IMAGE_TYPES.includes(imageType)) {
            return true;
        }
        else {
            setImageError(ERROR_UNSUPPORTED_IMAGE);
            return false;
        }
    }

    const handleImageUploadClick = () => {
        setImageError(null);
        imageUploadInputRef.current.click();
    }

    const handleImageUpload = (e) => {
        const image = e.target.files[0];
        if (checkImageType(image.type)) {
            uploadImage(image);
        }
    }

    const handleThumbClick = (e, id, path) => {
        setActiveImage({ id, path: getImageUrl(path) })
    }

    const handleImageEditClick = () => {
        setImageError(null);
        imageEditInputRef.current.click();
    }

    const handleImageEdit = (e) => {
        const image = e.target.files[0];
        if (checkImageType(image.type)) {
           uploadImage(image, activeImage);
        }
    }

    const handleImageDeleteClick = () => {
        setDeletePopupOpen(true);
    }

    const handleConfirmDelete = () => {
        dispatch(deleteAvatar(activeImage));
        setDeletePopupOpen(false);
    }

    const handleCancelDelete = () => {
        setDeletePopupOpen(false);
    }

    const handleInputChange = (e, param) => {
        const { name, value } = e.target;

        setProfileData({
            ...profileData,
            [name]: value
        });

        setValidationErrors({
            ...validationErrors,
            [name]: null,
        });

        setValidationError(null);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleRulesChange = (e) => {
        setRules(e.target.value);
    }

    const uploadImage = (image, currentImage = null) => {
        const formData = new FormData();
        formData.append('image', image);
        if (currentImage) {
            formData.append('id', currentImage.id);
            formData.append('path', currentImage.path);
        }
        dispatch(saveAvatar(formData));
    }

    const editParam = async (e, param) => {
        await setEditingParam(param);
        !params[param].field.mask && params[param].field.ref.current.focus();
    }

    const saveParam = async (e, param) => {
        setEditingParam(null);
        const formValid = await validateForm(param);
        if (formValid === true) {
            setValidationErrors({
                ...validationErrors,
                [param]: null,
            });
            setValidationError(null);
            dispatch(changeProfile(param, profileData[param]));
        }
    }

    const validateForm = async (param) => {
      try {
        if (params[param].validationSchema) {
            await params[param].validationSchema.validate({ [param]: profileData[param] }, { abortEarly: false });
            return true;
        }
        return true;
      } catch (errors) {
        console.log(errors.inner)
        let error = errors.inner[1] || errors.inner[0];
        return setError(param, error.message);
      }
    }

    const editDescription = async () => {
        await setEditingDescription(true);
        const textarea = descriptionRef.current;
        const textLength = textarea.value.length;
        textarea.setSelectionRange(textLength, textLength);
        textarea.focus();
    }

    const saveDescription = () => {
        setEditingDescription(false);
        if (description) {
            dispatch(changeProfile('description', description));
        }
    }

    const editRules = async () => {
        await setEditingRules(true);
        const textarea = rulesRef.current;
        const textLength = textarea.value.length;
        textarea.setSelectionRange(textLength, textLength);
        textarea.focus();
    }

    const saveRules = () => {
        setEditingRules(false);
        if (rules) {
            dispatch(changeProfile('rules', rules));
        }
    }

    return (
        <div className="profile container">
            <Breadcrumbs link="/" />
            <div className="profile-blocks d-flex flex-column">
                <div className="profile-data d-flex justify-content-between">
                    <div className="profile-data-left d-flex flex-column">
                        <div className="profile-data-gallery d-flex flex-column">
                            <div className="profile-data-gallery-main d-flex justify-content-center align-items-center relative">
                                <img src={activeImage.path} alt="" />
                                {isOwn && activeImage.id && <div className="profile-data-gallery-main-actions d-flex flex-column absolute">
                                    <input
                                        type="file"
                                        ref={imageEditInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleImageEdit}
                                    />
                                    <button className="btn-xs btn-black" onClick={handleImageEditClick}>
                                        <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                                    </button>
                                    <button className="btn-xs btn-black" onClick={handleImageDeleteClick}>
                                        <img className="btn-icon-trash" src={trash} alt="" />
                                    </button>
                                </div>}
                            </div>
                            <div className="profile-data-gallery-thumbs d-flex flex-wrap align-items-center">
                                {images.map((image, index) => {
                                    return (
                                        <div
                                            className="profile-data-gallery-thumb d-flex justify-content-center align-items-center"
                                            key={index}
                                            onClick={(e) => handleThumbClick(e, image.id, image.path)}
                                        >
                                            <img src={getImageUrl(image.path)} alt="" />
                                        </div>
                                    )
                                })}
                            </div>
                            <input
                                type="file"
                                ref={imageUploadInputRef}
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            {isOwn && <>
                                <div className="profile-data-gallery-download text-black" onClick={handleImageUploadClick}>
                                    Змінити або додати фото
                                </div>
                                {(imageError || apiError) && <div className="profile-error-message auth-message w-100 absolute text-red">{imageError || apiError}</div>}
                            </>}
                        </div>
                        <div className="profile-data-buttons d-flex flex-column">
                            {userId && <Link to={`/catalog/owner/${userId}`} className="btn btn-medium btn-black">
                                <img className="btn-icon-arrow" src={arrowWhiteLeft} alt="" />
                                <span>Перейти до каталогу</span>
                            </Link>}
                            {isOwn && <>
                                <Link to="/store" className="btn btn-medium btn-black">
                                    <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                                    <span>Редагування каталогу</span>
                                </Link>
                                <Link to="/account" className="btn btn-medium btn-black">
                                    <img className="btn-icon-settings" src={settingsWhite} alt="" />
                                    <span>Налаштування акаунту</span>
                                </Link>
                            </>}
                        </div>
                    </div>
                    <div className="profile-data-right d-flex flex-column relative">
                        {validationError && <div className="profile-error-message auth-message w-100 absolute text-center text-red">{validationError}</div>}
                        <div className="profile-data-fields d-flex flex-column">
                            {Object.keys(params).map((param, index) => {
                                const item = params[param];
                                const { field, editable } = item;
                                const isEditing = editingParam === param;
                                const { label, name, mask, value, ref } = field;
                                return (
                                    <div className="profile-data-field d-flex" key={index}>
                                        <div className="profile-data-field-label d-flex align-items-center text-black">{label}</div>
                                        <div className="profile-data-field-input d-flex justify-content-between align-items-center">
                                            {isEditing 
                                                ?   (mask 
                                                        ? <InputMask
                                                            className="w-100 h-100"
                                                            type="text"
                                                            name={name}
                                                            mask={mask}
                                                            value={value}
                                                            ref={ref}
                                                            placeholder={label}
                                                            onChange={(e) => handleInputChange(e, param)}
                                                        />
                                                        : <input className="w-100 h-100 text-black" type="text" name={name} value={value} ref={ref} placeholder={label} onChange={(e) => handleInputChange(e, param)} />
                                                    )
                                                :   <span>{value}</span>
                                            }
                                            <div className="profile-data-field-icon">
                                                {editable && 
                                                    (isEditing 
                                                        ? <img className="w-100" src={ok} alt="" onClick={(e) => saveParam(e, param)} />
                                                        : <img className="w-100" src={pencil} alt="" onClick={(e) => editParam(e, param)} />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <ProfileSocials profileData={profileData} />
                    </div>
                </div>
                <div className="profile-products d-flex flex-column align-items-center">
                    <div className="profile-products-title">Популярні товари</div>
                    {userPopularProducts.length !== 0 && <div className="w-100 relative">
                        {/*<div className="profile-products-nav profile-products-nav-prev absolute">
                            <img className="w-100" src={chevronLeft} alt="" />
                        </div>*/}
                        <div className="profile-products-slider d-flex justify-content-center">
                            {userPopularProducts.map((product, index) => {
                                return (
                                    <ProductCard
                                        product={product}
                                        selectAmount={!isOwn}
                                        addButton={!isOwn}
                                        favIcon={!isOwn}
                                        key={index} 
                                    />
                                )
                            })}
                        </div>
                        {/*<div className="profile-products-nav profile-products-nav-next absolute">
                            <img className="w-100" src={chevronRight} alt="" />
                        </div>*/}
                    </div>}
                    {/*{isOwn && <div className="w-100 d-flex justify-content-end">
                        <button className="profile-products-add btn-medium btn-black">
                            <img className="btn-icon-plus" src={plusWhite} alt="" />
                            <span>Додати популярний товар</span>
                        </button>
                    </div>}*/}
                </div>
                <div className="profile-description d-flex flex-column align-items-center">
                    <div className="profile-description-title">Опис</div>
                    <div className="profile-description-field w-100 d-flex flex-column align-items-end">
                        <div className="profile-description-field-textarea w-100 flex-1">
                            {isOwn && editingDescription 
                                ? <textarea
                                    className="w-100 h-100"
                                    value={description}
                                    ref={descriptionRef}
                                    onChange={(e) => handleDescriptionChange(e)}
                                  ></textarea>
                                : <div>{description}</div>
                            }
                        </div>
                        {isOwn && <div className="profile-description-field-icon">
                            {editingDescription 
                                ? <img className="w-100" src={ok} alt="" onClick={saveDescription} />
                                : <img className="w-100" src={pencil} alt="" onClick={editDescription} />
                            }
                        </div>}
                    </div>
                    {isOwn && <div className="profile-description-actions w-100 d-flex justify-content-end">
                        <button className="btn-small btn-black" onClick={saveDescription}>
                            <span>Зберегти</span>
                        </button>
                        <button className="btn-small btn-black" onClick={editDescription}>
                            <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                            <span>Змінити</span>
                        </button>
                    </div>}
                </div>
                <div className="profile-rules d-flex flex-column align-items-center">
                    <div className="profile-rules-title">Правила орендаря</div>
                    <div className="profile-rules-field w-100 d-flex flex-column align-items-end">
                        <div className="profile-rules-field-textarea w-100 flex-1">
                            {isOwn && editingRules 
                                ? <textarea
                                    className="w-100 h-100"
                                    value={rules}
                                    ref={rulesRef}
                                    onChange={(e) => handleRulesChange(e)}
                                  ></textarea>
                                : <div>{rules}</div>
                            }
                        </div>
                        {isOwn && <div className="profile-rules-field-icon">
                            {editingRules 
                                ? <img className="w-100" src={ok} alt="" onClick={saveRules} />
                                : <img className="w-100" src={pencil} alt="" onClick={editRules} />
                            }
                        </div>}
                    </div>
                    {isOwn && <div className="profile-rules-actions w-100 d-flex justify-content-end">
                        <button className="btn-small btn-black" onClick={saveRules}>
                            <span>Зберегти</span>
                        </button>
                        <button className="btn-small btn-black" onClick={editRules}>
                            <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                            <span>Змінити</span>
                        </button>
                    </div>}
                </div>
            </div>
            <DeleteImage
                isOpen={deletePopupOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    )
}

export default Profile;
