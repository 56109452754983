import { combineReducers }    from 'redux';
import authReducer            from './auth';
import accountReducer         from './account';
import profileReducer         from './profile';
import catalogReducer         from './catalog';
import productReducer         from './product';
import projectReducer         from './project';
import orderReducer           from './order';
import dialogReducer          from './dialog';
import chatReducer            from './chat';
import subscriptionReducer    from './subscription';
import paymentReducer         from './payment';
import statsReducer           from './stats';
import notificationReducer    from './notification';

const rootReducer = combineReducers({
  auth:         authReducer,
  account:      accountReducer,
  profile:      profileReducer,
  catalog:      catalogReducer,
  product:      productReducer,
  project:      projectReducer,
  order:        orderReducer,
  dialog:       dialogReducer,
  chat:         chatReducer,
  subscription: subscriptionReducer,
  payment:      paymentReducer,
  stats:        statsReducer,
  notification: notificationReducer,
});

export default rootReducer;
