import NotificationService from '../services/notification.service';

export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE';

export const getNotificationsSuccess = (data) => ({ type: GET_NOTIFICATIONS_SUCCESS, payload: data });
export const getNotificationsFailure = (error) => ({ type: GET_NOTIFICATIONS_FAILURE, payload: error });
export const readNotificationSuccess = () => ({ type: READ_NOTIFICATION_SUCCESS });
export const readNotificationFailure = (error) => ({ type: READ_NOTIFICATION_FAILURE, payload: error });

export const getMyNotifications = () => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        const result = await NotificationService.getMyNotifications(token);
        console.log(result)
        if (result.success) {
            dispatch(getNotificationsSuccess(result.notifications));
            return result.notifications;
        }
        else {
            dispatch(getNotificationsFailure(result.message));
            return false;
        }
    } catch (e) {
        dispatch(getNotificationsFailure(e.toString()));
        return false;
    }
}

export const readNotification = (payload) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');
        const result = await NotificationService.readNotification(payload, token);
        console.log(result)
        if (result.success) {
            dispatch(readNotificationSuccess());
            dispatch(getMyNotifications());
            return true;
        }
        else {
            dispatch(readNotificationFailure(result.message));
            return false;
        }
    } catch (e) {
        dispatch(readNotificationFailure(e.toString()));
        return false;
    }
}
  