import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	createProduct,
	getProductBySlug,
	setProductView,
    changeProduct,
    setProductCategories,
	saveProductImage,
	deleteProductImage,
    addProductComment,
	setProductActivation,
	deleteProduct
} from '../../actions/product';
import { addOrderProduct } from '../../actions/order';
import * as C from '../../constants/product';
import { addFavorite, deleteFavorite } from '../../actions/auth';
import { ALLOWED_IMAGE_TYPES, ERROR_UNSUPPORTED_IMAGE } from '../../constants/auth';
import { API_URL } from '../../constants/api';
import {
    titleSchema,
    rentalPriceSchema,
    restorePriceSchema,
    refundPriceSchema,
    costSchema,
    totalAmountSchema,
    // freeAmountSchema,
    colorSchema,
    lengthSchema,
    widthSchema,
    heightSchema,
    weightSchema,
    packagingSchema,
    minPersonsSchema,
} from '../../validations/product';
import Breadcrumbs from '../Common/Breadcrumbs';
import DeleteImage from '../Popup/DeleteImage';
import AddFavorite from '../Popup/AddFavorite';
import DeleteFavorite from '../Popup/DeleteFavorite';
import ProductComments from './ProductComments';
import CategorySelection from '../Catalog/CategorySelection';
import ProductCalendar from './ProductCalendar';

import noImage from '../../assets/images/product-no-image.png';
import plusWhite from '../../assets/icons/plus-white.svg';
import pencilWhite from '../../assets/icons/pencil-white.svg';
import trash from '../../assets/icons/trash.svg';
import pencil from '../../assets/icons/pencil.svg';
import ok from '../../assets/icons/ok-black.svg';
import arrowRight from '../../assets/icons/arrow-white-right.svg';
import smilePlus from '../../assets/icons/smile-plus.svg';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';

const Product = () => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const slug = pathArray.length === 3 ? pathArray[2] : null;
    // console.log(slug)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const apiError = useSelector((state) => state.product.productError);
    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const selectedOrderInfo = useSelector((state) => state.order.selectedOrderInfo);
    const product = useSelector((state) => state.product.product);
    const productLoading = useSelector((state) => state.auth.productLoading);

    const [isOwn, setIsOwn] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [mode, setMode] = useState(null);
    const [activeProject, setActiveProject] = useState(null);
    const [activeOrderInfo, setActiveOrderInfo] = useState(null);
    const [addedToProject, setAddedToProject] = useState(false);

    const initialProductData = {
        title: '',
        rentalPrice: '',
        restorePrice: '',
        refundPrice: '',
        cost: '',
        categories: [],
        totalAmount: '',
        // freeAmount: '',
        color: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        packaging: '',
        minPersons: '',
    }

    const [productData, setProductData] = useState(initialProductData);
    const [description, setDescription] = useState('');

    useEffect(() => {
        const fetchProductData = async () => {
	    	// if (isAuthenticated) {
	    		if (slug) {
	    			if (slug == 'create') {
                        setProductData(initialProductData);
                        setDescription('');
			    		setIsOwn(true);
			    		setMode('create');

	    			} else {
	            		const resultProduct = await dispatch(getProductBySlug(slug));
	            		if (resultProduct) {
                            dispatch(setProductView(resultProduct.id))
	            			if (authUser && resultProduct.ownerId === authUser.id) {
					    		setIsOwn(true);
					    		setMode('edit');
	            			} else {
					    		setIsOwn(false);
					    		setMode('show');
	            			}
	            		} else {
	                		navigate('/404');            			
	            		}
	    			}
	    		} else {
		    		navigate('/');
		    	}
	    	// } else if (!userLoading) {
	    		// navigate('/');
	    	// }
        }

        fetchProductData();
    }, [slug, /*userLoading, isAuthenticated, */authUser]);

    useEffect(() => {
        if (authUser) {
            const projects = Object.values(authUser.projects);
            const orders = Object.values(authUser.ownerOrders);

            if (selectedProject || selectedOrderInfo) {
                if (selectedProject) {
                    setActiveProject(selectedProject);
                    setActiveOrderInfo(null);
                }
                if (selectedOrderInfo) {
                    setActiveOrderInfo(selectedOrderInfo);
                    setActiveProject(null);
                }
            }
            else {
                if (projects.length !== 0) {
                    setActiveProject(projects.find(project => project.selected));
                }
                if (orders.length !== 0) {
                    setActiveOrderInfo(orders
                                        .filter(order => order.info)
                                        .map(order => order.info)
                                        .find(info => info.selected));
                }
            }
        }
    }, [authUser, selectedProject, selectedOrderInfo]);

    const imageUploadInputRef = useRef();
    const imageEditInputRef = useRef();

    const [activeImage, setActiveImage] = useState({ id: null, path: noImage });
    const [images, setImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);

    const [deleteImagePopupOpen, setDeleteImagePopupOpen] = useState(false);
    const [addFavoritePopupOpen, setAddFavoritePopupOpen] = useState(false);
    const [deleteFavoritePopupOpen, setDeleteFavoritePopupOpen] = useState(false);
    const [categorySelectionOpen, setCategorySelectionOpen] = useState(false);

    const [editingParam, setEditingParam] = useState(null);

    const [editingDescription, setEditingDescription] = useState(false);
    const descriptionRef = useRef(null);

    const [comment, setComment] = useState('');

    const [history, setHistory] = useState([]);

    const params = {
        title: {
            field: { label: C.TITLE, name: 'title', value: productData.title, ref: useRef(null) },
            validationSchema: titleSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        rentalPrice: {
            field: { label: C.RENTAL_PRICE, name: 'rentalPrice', value: productData.rentalPrice, ref: useRef(null) },
            validationSchema: rentalPriceSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        restorePrice: {
            field: { label: C.RESTORE_PRICE, name: 'restorePrice', value: productData.restorePrice, ref: useRef(null) },
            validationSchema: restorePriceSchema,
            visible: isOwn,
            editable: isOwn,
            isEditing: false
        },
        refundPrice: {
            field: { label: C.REFUND_PRICE, name: 'refundPrice', value: productData.refundPrice, ref: useRef(null) },
            validationSchema: refundPriceSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        cost: {
            field: { label: C.COST, name: 'cost', value: productData.cost, ref: useRef(null) },
            validationSchema: costSchema,
            visible: isOwn,
            editable: isOwn,
            isEditing: false
        },
        categories: {
            field: { label: C.CATEGORY, name: 'categories', value: productData.categories, ref: useRef(null) },
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        totalAmount: {
            field: { label: C.TOTAL_AMOUNT, name: 'totalAmount', value: productData.totalAmount, ref: useRef(null) },
            validationSchema: totalAmountSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        /*freeAmount: {
            field: { label: C.FREE_AMOUNT, name: 'freeAmount', value: productData.freeAmount, ref: useRef(null) },
            validationSchema: freeAmountSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },*/
        color: {
            field: { label: C.COLOR, name: 'color', value: productData.color, ref: useRef(null) },
            validationSchema: colorSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        length: {
            field: { label: C.LENGTH, name: 'length', value: productData.length, ref: useRef(null) },
            validationSchema: lengthSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        width: {
            field: { label: C.WIDTH, name: 'width', value: productData.width, ref: useRef(null) },
            validationSchema: widthSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        height: {
            field: { label: C.HEIGHT, name: 'height', value: productData.height, ref: useRef(null) },
            validationSchema: heightSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        weight: {
            field: { label: C.WEIGHT, name: 'weight', value: productData.weight, ref: useRef(null) },
            validationSchema: weightSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        packaging: {
            field: { label: C.PACKAGING, name: 'packaging', value: productData.packaging, ref: useRef(null) },
            validationSchema: packagingSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
        minPersons: {
            field: { label: C.MIN_PERSONS, name: 'minPersons', value: productData.minPersons, ref: useRef(null) },
            validationSchema: minPersonsSchema,
            visible: true,
            editable: isOwn,
            isEditing: false
        },
    }

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else return `${API_URL}/${path}`;
    }

    useEffect(() => {
        if (product && slug !== 'create' && authUser) {
            if (product.images && Object.keys(product.images).length) {
                setActiveImage({ id: product.images[0].id, path: getImageUrl(product.images[0].path) });
                setImages(Object.values(product.images));
            }
            else {
                setActiveImage({ id: null, path: noImage });
                setImages([]);
            }

            setProductData({                
                title: product.features.title || '',
                rentalPrice: product.features.rentalPrice || '',
                restorePrice: product.features.restorePrice || '',
                refundPrice: product.features.refundPrice || '',
                cost: product.features.cost || '',
                categories: product.categories || [],
                totalAmount: product.features.totalAmount || '',
                // freeAmount: product.features.freeAmount || '',
                color: product.features.color || '',
                length: product.features.length || '',
                width: product.features.width || '',
                height: product.features.height || '',
                weight: product.features.weight || '',
                packaging: product.features.packaging || '',
                minPersons: product.features.minPersons || '',
            });
            
            const isFavorite = product.favorites.filter(item => item.userId === authUser.id).length !== 0;
            setIsFavorite(isFavorite);

            setDescription(product.features.description || '');
            setHistory(product.orderItems.filter(item => item.status === 'completed'));
        }
    }, [product, slug, authUser]);

    const [imageError, setImageError] = useState(null);

    const [validationErrors, setValidationErrors] = useState({
        title: null,
        rentalPrice: null,
        restorePrice: null,
        refundPrice: null,
        cost: null,
        totalAmount: null,
        // freeAmount: null,
        color: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        packaging: null,
        minPersons: null,
    });

    const [validationError, setValidationError] = useState(null);

    const setError = (name, message) => {
        setValidationErrors({
            ...validationErrors,
            [name]: message,
        });
        setValidationError(message);
        return false;
    }

    const checkImageType = (imageType) => {
        if (ALLOWED_IMAGE_TYPES.includes(imageType)) {
            return true;
        }
        else {
            setImageError(ERROR_UNSUPPORTED_IMAGE);
            return false;
        }
    }

    const handleImageUploadClick = () => {
        setImageError(null);
        imageUploadInputRef.current.click();
    }

    const handleImageUpload = (e) => {
        const image = e.target.files[0];
        if (checkImageType(image.type)) {
            if (mode === 'edit') {
                uploadImage(image);
            } else if (mode === 'create') {
                setUploadedImages(prevImages => [ ...prevImages, image ]);
            }
        }
    }

    const handleThumbClick = (e, id, path) => {
        setActiveImage({ id, path: getImageUrl(path) })
    }

    const handleImageEditClick = () => {
        setImageError(null);
        imageEditInputRef.current.click();
    }

    const handleImageEdit = (e) => {
        const image = e.target.files[0];
        if (checkImageType(image.type)) {
            uploadImage(image, activeImage);
        }
    }

    const handleImageDeleteClick = () => {
        setDeleteImagePopupOpen(true);
    }

    const handleConfirmDeleteImage = async () => {
        await dispatch(deleteProductImage({ ...activeImage, productSlug: slug }));
    	await dispatch(getProductBySlug(slug));
        setDeleteImagePopupOpen(false);
    }

    const handleCancelDeleteImage = () => {
        setDeleteImagePopupOpen(false);
    }

    const handleAddToProjectClick = async () => {
        if (product && authUser) {
            if (activeProject) {
                await dispatch(addOrderProduct({ project: activeProject, product, amount: 1 }));
                setAddedToProject(true);
            } else if (activeOrderInfo) {
                await dispatch(addOrderProduct({ order: activeOrderInfo.order, product, amount: 1 }));
                setAddedToProject(true);
            } else {
                navigate('/project/create');
            }
        } else {
            navigate('/register')
        }
    }

    useEffect(() => {
        if (addedToProject) {
            setTimeout(() => setAddedToProject(false), 3000);
        }
    }, [addedToProject]);

    const handleAddFavoriteClick = () => {
        if (authUser) {
            setAddFavoritePopupOpen(true);
        } else {
            navigate('/register')
        }
    }

    const handleConfirmAddFavorite = async (listId) => {
        await dispatch(addFavorite({ productId: product.id, listId }));
        await dispatch(getProductBySlug(slug));
        setAddFavoritePopupOpen(false);
    }

    const handleCancelAddFavorite = () => {
        setAddFavoritePopupOpen(false);
    }

    const handleDeleteFavoriteClick = () => {
        setDeleteFavoritePopupOpen(true);
    }

    const handleConfirmDeleteFavorite = async () => {
        await dispatch(deleteFavorite({ productId: product.id }));
        await dispatch(getProductBySlug(slug));
        setDeleteFavoritePopupOpen(false);
    }

    const handleCancelDeleteFavorite = () => {
        setDeleteFavoritePopupOpen(false);
    }

    const handleInputChange = (e, param) => {
        const { name, value } = e.target;

        setProductData({
            ...productData,
            [name]: value
        });

        setValidationErrors({
            ...validationErrors,
            [name]: null,
        });

        setValidationError(null);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleCommenthange = (e) => {
        setComment(e.target.value);
    }

    const uploadImage = async (image, currentImage = null) => {
        const formData = new FormData();
        formData.append('slug', slug);
        formData.append('image', image);
        if (currentImage) {
            formData.append('id', currentImage.id);
            formData.append('path', currentImage.path);
        }
        await dispatch(saveProductImage(formData));
    	await dispatch(getProductBySlug(slug));
    }

    const editParam = async (e, param) => {
        await setEditingParam(param);
        !params[param].field.mask && params[param].field.ref.current.focus();
    }

    const saveParam = async (e, param) => {
        setEditingParam(null);
        const formValid = await validateParam(param);
        if (formValid === true) {
            setValidationErrors({
                ...validationErrors,
                [param]: null,
            });
            setValidationError(null);
            if (mode == 'edit') {
            	dispatch(changeProduct(product.id, param, productData[param]));
            }
        }
    }

    const validateParam = async (param) => {
      try {
        await params[param].validationSchema.validate({ [param]: productData[param] }, { abortEarly: false });
        return true;
      } catch (errors) {
        // console.log(errors.inner)
        let error = errors.inner[1] || errors.inner[0];
        return setError(param, error.message);
      }
    }

    const setCategories = async (selectedCategories) => {
        if (selectedCategories.length) {
            const categories = selectedCategories.map(category => {
                const { id, title } = category;
                return { id, title }
            });
            setProductData({
                ...productData,
                categories,
            });
            if (mode == 'edit') {
                await dispatch(setProductCategories(product.id, categories))
            }
        }
    }

    const editDescription = async () => {
        await setEditingDescription(true);
        const textarea = descriptionRef.current;
        const textLength = textarea.value.length;
        textarea.setSelectionRange(textLength, textLength);
        textarea.focus();
    }

    const saveDescription = () => {
        setEditingDescription(false);
        if (description) {
            if (mode == 'edit') {
            	dispatch(changeProduct(product.id, 'description', description));
            }
        }
    }

    const saveComment = async () => {
        if (comment) {
            if (mode == 'show') {
                await dispatch(addProductComment(product.id, comment));
                await dispatch(getProductBySlug(slug));
                setComment('');
            }
        }
    }

    const validateForm = async () => {
        let validatingParam = null;
        try {
            for (const param in params) {
                if (param != 'categories') {
                    validatingParam = param
                    await params[param].validationSchema.validate({ [param]: productData[param] }, { abortEarly: false });
                }
            }
            return true;
        } catch (errors) {
            // console.log(errors)
            let error = errors.inner[1] || errors.inner[0];
            return setError(validatingParam, error.message);
        }
    }

    const saveProduct = async () => {
        if (mode == 'create') {
        	const payload = { ...productData, description }
            const formValid = await validateForm();
            if (formValid === true) {
                const createdProduct = await dispatch(createProduct(payload));
                for (const image of uploadedImages) {
                    const formData = new FormData();
                    formData.append('slug', createdProduct.slug);
                    formData.append('image', image);
                    await dispatch(saveProductImage(formData));
                }
                navigate(`/product/${createdProduct.slug}`);
            }
        }
    }

    const setActivation = async (active) => {
        if (mode == 'edit') {
    		await dispatch(setProductActivation(product.id, active));
    		await dispatch(getProductBySlug(slug));
    	}    	
    }

    const removeProduct = async () => {
        if (mode == 'edit') {
    		await dispatch(deleteProduct(product.id));
    		navigate('/store');
    	}
    }

    return (
        <div className="product container">
            <Breadcrumbs link="/store" />
            <div className="product-blocks d-flex flex-column">
                <div className="product-data d-flex justify-content-between">
                    <div className="product-data-left d-flex flex-column">
                        <div className="product-data-gallery d-flex flex-column relative">
                            <div className="product-data-gallery-main d-flex justify-content-center align-items-center relative">
                                {mode === 'create' && uploadedImages.length !== 0 
                                    ? <img src={URL.createObjectURL(uploadedImages[0])} alt="" />
                                    : <img src={activeImage.path} alt="" />
                                }
                                {isOwn && activeImage.id && <div className="product-data-gallery-main-actions d-flex flex-column absolute">
                                    <input
                                        type="file"
                                        ref={imageEditInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleImageEdit}
                                    />
                                    <button className="btn-xs btn-black" onClick={handleImageEditClick}>
                                        <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                                    </button>
                                    <button className="btn-xs btn-black" onClick={handleImageDeleteClick}>
                                        <img className="btn-icon-trash" src={trash} alt="" />
                                    </button>
                                </div>}
                            </div>
                            <div className="product-data-gallery-thumbs d-flex flex-wrap align-items-center">
                                {mode !== 'create' && images.map((image, index) => {
                                    return (
                                        <div
                                            className="product-data-gallery-thumb d-flex justify-content-center align-items-center"
                                            key={index}
                                            onClick={(e) => handleThumbClick(e, image.id, image.path)}
                                        >
                                            <img src={getImageUrl(image.path)} alt="" />
                                        </div>
                                    )
                                })}
                                {mode === 'create' && uploadedImages.map((image, index) => {
                                    return (
                                        <div
                                            className="product-data-gallery-thumb d-flex justify-content-center align-items-center"
                                            key={index}
                                        >
                                            <img src={URL.createObjectURL(image)} alt="" />
                                        </div>
                                    )
                                })}
                            </div>
                            <input
                                type="file"
                                ref={imageUploadInputRef}
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                            {isOwn && (imageError || apiError) && <div className="product-error-message auth-message w-100 absolute text-red">{imageError || apiError}</div>}
                        </div>
                        <div className="product-data-buttons d-flex flex-column">
                            {isOwn && <button className="btn-ms btn-black" onClick={handleImageUploadClick}>
                                <img className="btn-icon-plus" src={plusWhite} alt="" />
                                <span className="w-100 flex-1">Додати фото</span>
                            </button>}
                            {/*<button className="btn-ms btn-black">
                                <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                                <span className="w-100 flex-1">Редагувати фото</span>
                            </button>*/}
                            {product && isOwn && <>
	                            {!product.active && <button className="btn-ms btn-black" onClick={() => setActivation(true)}>
	                                <span>Активувати товар</span>
	                            </button>}
	                            {product.active && <button className="btn-ms btn-black" onClick={() => setActivation(false)}>
	                                <span>Деактивувати товар</span>
	                            </button>}
	                            <button className="btn-ms btn-black" onClick={removeProduct}>
                                	<img className="btn-icon-trash" src={trash} alt="" />
	                                <span className="w-100 flex-1">Видалити товар</span>
	                            </button>
                        	</>}
                            {product && !isOwn && <>
	                            <button className="btn-ms btn-black" onClick={handleAddToProjectClick}>
	                                <img className="btn-icon-plus" src={plusWhite} alt="" />
	                                <span className="w-100 flex-1">
                                        {addedToProject ? 'Додано!' : 'Додати до проекту'}
                                    </span>
	                            </button>
	                            {isFavorite
                                    ?   <button className="btn-ms btn-black" onClick={handleDeleteFavoriteClick}>
    	                                   <span>Видалити зі списку</span>
                                        </button>
                                    :   <button className="btn-ms btn-black" onClick={handleAddFavoriteClick}>
                                            <span>Зберегти в список</span>
                                        </button>
                                }
                        	</>}
                        </div>
                    </div>
                    <div className="product-data-right d-flex flex-column relative">
                        {(validationError || apiError) && <div className="product-error-message auth-message w-100 absolute text-center text-red">{validationError || apiError}</div>}
                        <div className="product-data-fields d-flex flex-column">
                            {Object.keys(params).filter(param => params[param].visible)
                                                .map((param, index) => {
                                const item = params[param];
                                const { field, editable } = item;
                                const isEditing = editingParam === param;
                                const { label, name, value, ref } = field;
                                return (
                                    <div className={`product-data-field product-data-field-${name} d-flex`} key={index}>
                                        <div className="product-data-field-label d-flex align-items-center text-black">{label}</div>
                                        <div className="product-data-field-input d-flex justify-content-between align-items-center">
                                            {name != 'categories' 
                                                ? (isEditing 
                                                    ? <input className="w-100 h-100 text-black" type="text" name={name} value={value} ref={ref} placeholder={label} onChange={(e) => handleInputChange(e, param)} />
                                                    : <span>{value}</span>
                                                ) 
                                                : <div>
                                                    {value.map((cat, ci) => {
                                                        return <div key={ci}>{cat.title}</div>
                                                    })}
                                                </div>
                                            }
                                            <div className="product-data-field-icon">
                                                {editable && name != 'categories' && 
                                                    (isEditing 
                                                        ? <img className="w-100" src={ok} alt="" onClick={(e) => saveParam(e, param)} />
                                                        : <img className="w-100" src={pencil} alt="" onClick={(e) => editParam(e, param)} />
                                                    )
                                                }
                                                {editable && name == 'categories' && 
                                                    <img className="w-100" src={pencil} alt="" onClick={() => setCategorySelectionOpen(true)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {product && !isOwn && <div className="product-data-right-owner d-flex justify-content-end align-items-center">
                        	<Link to={`/profile/${product.owner.slug}`} className="btn btn-medium btn-black">
                                <span>Перейти на сторінку власника</span>
                            	<img className="btn-icon-arrow" src={arrowRight} alt="" />
                        	</Link>
                        </div>}
                    </div>
                </div>
                {product && <div className="product-calendar-wrap d-flex flex-column align-items-center">
                    <div className="product-calendar-title">Календар оренди</div>
                    <div className="product-calendar-body d-flex flex-column align-items-center">
                        <ProductCalendar product={product} />
                        <div className="product-calendar-legend d-flex justify-content-center align-items-center">
                            <div className="product-calendar-legend-item d-flex align-items-center">
                                <div className="product-calendar-legend-item-marker product-calendar-legend-item-marker-red"></div>
                                <div className="product-calendar-legend-item-text">Зайнято</div>
                            </div>
                            <div className="product-calendar-legend-item d-flex align-items-center">
                                <div className="product-calendar-legend-item-marker product-calendar-legend-item-marker-green"></div>
                                <div className="product-calendar-legend-item-text">Вільно</div>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="product-description d-flex flex-column align-items-center">
                    <div className="product-description-title">Опис</div>
                    <div className="product-description-field w-100 d-flex flex-column align-items-end">
                        <div className="product-description-field-textarea w-100 flex-1">
                            {isOwn && editingDescription 
                                ? <textarea
                                    className="w-100 h-100"
                                    value={description}
                                    ref={descriptionRef}
                                    onChange={(e) => handleDescriptionChange(e)}
                                  ></textarea>
                                : <div>{description}</div>
                            }
                        </div>
                        {isOwn && <div className="product-description-field-icon">
                            {editingDescription 
                                ? <img className="w-100" src={ok} alt="" onClick={saveDescription} />
                                : <img className="w-100" src={pencil} alt="" onClick={editDescription} />
                            }
                        </div>}
                    </div>
                    {isOwn && <div className="product-description-actions w-100 d-flex justify-content-end">
                        <button className="btn-small btn-black" onClick={saveDescription}>
                            <span>Зберегти</span>
                        </button>
                        <button className="btn-small btn-black" onClick={editDescription}>
                            <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                            <span>Змінити</span>
                        </button>
                    </div>}
                </div>
                {product && <div className="product-comments d-flex flex-column align-items-center">
                	<div className="product-comments-title">Коментарі ({product.comments.length})</div>
                	{product.comments.length !== 0 && <div className="product-comments-carousel w-100">
                        <ProductComments comments={product.comments} />
                	</div>}
                    {product.comments.length === 0 && <div className="product-comments-none">
                        У товару поки що немає коментарів
                    </div>}
                    {mode == 'show' && authUser && <div className="product-comments-form w-100 d-flex flex-column">
                        <div className="product-comments-form-field d-flex justify-content-center align-items-center">
                            <textarea
                                placeholder="Ваш коментар"
                                value={comment}
                                onChange={(e) => handleCommenthange(e)}
                            ></textarea>
                            <img src={smilePlus} />
                        </div>
                        <div className="product-comments-form-submit d-flex justify-content-end align-items-center">
                            <button className="btn-medium btn-black" onClick={saveComment}>
                                <img className="btn-icon-plus" src={plusWhite} alt="" />
                                <span>Додати коментар</span>                                
                            </button>
                        </div>
                    </div>}
                </div>}
                {mode == 'create' && <div className="product-save d-flex justify-content-center align-items-center">
            		<button className="btn-small btn-black" onClick={saveProduct}>
            			<span>Зберегти товар</span>
            		</button>                	
                </div>}
                {product && history.length !== 0 && 
                    <div className="product-history d-flex flex-column align-items-center">
                        <div className="product-history-title">Історія замовлень</div>
                        <Table className="product-history-table w-100">
                            <TableHead>
                                <TableRow className="product-history-table-row">
                                    <TableCell className="product-history-table-cell">Дата видачі</TableCell>
                                    <TableCell className="product-history-table-cell">Дата отримання</TableCell>
                                    <TableCell className="product-history-table-cell">Замовник</TableCell>
                                    <TableCell className="product-history-table-cell">Контактні дані замовника</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {history.map((item, index) => {
                                    const { receiveDate, returnDate } = item.order;
                                    const { firstname, lastname } = item.order.customer ? item.order.customer.profile : item.order.info;
                                    const { phone } = item.order.customer || item.order.info;
                                    return (
                                        <TableRow className="product-history-table-row" key={index}>
                                            <TableCell className="product-history-table-cell">{
                                                new Intl.DateTimeFormat('uk-UA', {
                                                    day: "numeric",
                                                    month: "numeric",
                                                    year: "numeric",
                                                    timeZone: 'Europe/Kyiv',
                                                }).format(new Date(item.order.receiveDate))
                                            }</TableCell>
                                            <TableCell className="product-history-table-cell">{
                                                new Intl.DateTimeFormat('uk-UA', {
                                                    day: "numeric",
                                                    month: "numeric",
                                                    year: "numeric",
                                                    timeZone: 'Europe/Kyiv',
                                                }).format(new Date(item.order.returnDate))
                                            }</TableCell>
                                            <TableCell className="product-history-table-cell">{
                                                `${firstname} ${lastname}`
                                            }</TableCell>
                                            <TableCell className="product-history-table-cell">{phone}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                }
            </div>
            <DeleteImage
                isOpen={deleteImagePopupOpen}
                onConfirm={handleConfirmDeleteImage}
                onCancel={handleCancelDeleteImage}
            />
            <AddFavorite
                isOpen={addFavoritePopupOpen}
                onConfirm={handleConfirmAddFavorite}
                onCancel={handleCancelAddFavorite}
            />
            <DeleteFavorite
                isOpen={deleteFavoritePopupOpen}
                onConfirm={handleConfirmDeleteFavorite}
                onCancel={handleCancelDeleteFavorite}
            />
            <CategorySelection
                isOpen={categorySelectionOpen}
                onClose={() => setCategorySelectionOpen(false)}
                onSelected={setCategories}
            />
        </div>
    )
}

export default Product;
