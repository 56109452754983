import api from './api';

class StatsService
{
	async getStats(token) {
		try {
			const response = await api.get('/stats', {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[StatsService][getStats][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new StatsService();
