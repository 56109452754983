import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ title, items }) => {
	const [data, setData] = useState({ labels: [], datasets: [] })

	useEffect(() => {
		if (items && Object.keys(items).length > 0) {
			setData({
				labels: Object.keys(items),
				datasets: [
					{
        				label: title || '',
						data: Object.values(items),
						backgroundColor: 'lightgrey',
						borderColor: 'black',
        				borderWidth: 3
					}
				]
			});
		}
	}, [title, items]);

	const options = {
		responsive: true,
		scales: {
			y: {
				ticks: {
					stepSize: 1
				},
			},
		},
	}

  	return <Bar data={data} options={options} />
}

export default BarChart;
