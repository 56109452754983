import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, changePhone, changeEmail, discardChanged, deleteAccount } from '../../actions/account';
import { passwordSchema, phoneSchema, emailSchema } from '../../validations/account';
import { PHONE_MASK } from '../../constants/auth';
import InputMask from 'react-input-mask';
import Breadcrumbs from '../Common/Breadcrumbs';
import DeleteAccount from '../Popup/DeleteAccount';
import pencil from '../../assets/icons/pencil.svg';
import trash from '../../assets/icons/trash.svg';

const Account = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const apiError = useSelector((state) => state.account.error);
    const userLoading = useSelector((state) => state.auth.userLoading);
    const user = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const passwordChanged = useSelector((state) => state.auth.passwordChanged);
    const phoneChanged = useSelector((state) => state.auth.phoneChanged);
    const emailChanged = useSelector((state) => state.auth.emailChanged);

    const [passwordData, setPasswordData] = useState({
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
    });

    const [phoneData, setPhoneData] = useState({
        phone: user ? user.phone : '',
    });

    const [emailData, setEmailData] = useState({
        email: user ? user.email : '',
    });

    const [updatingParam, setUpdatingParam] = useState(null);

    const [deletePopupOpen, setDeletePopupOpen] = useState(false);

    useEffect(() => {
        if (user) {
            setPhoneData((prevPhoneData) => ({
                ...prevPhoneData,
                phone: user.phone,
            }));

            setEmailData((prevEmailData) => ({
                ...prevEmailData,
                email: user.email,
            }));
        }
    }, [user]);

    const params = {
        password: {
            fields: [
                { label: 'Старий пароль', name: 'old_password', value: passwordData.old_password },
                { label: 'Новий пароль', name: 'new_password', value: passwordData.new_password },
                { label: 'Підтвердження паролю', name: 'new_password_confirmation', value: passwordData.new_password_confirmation },
            ],
            stateData: passwordData,
            setStateData: setPasswordData,
            validationSchema: passwordSchema,
            action: changePassword
        },
        phone: {
            field: { label: 'Номер телефону', name: 'phone', value: phoneData.phone },
            stateData: phoneData,
            setStateData: setPhoneData,
            validationSchema: phoneSchema,
            action: changePhone
        },
        email: {
            field: { label: 'Email', name: 'email', value: emailData.email },
            stateData: emailData,
            setStateData: setEmailData,
            validationSchema: emailSchema,
            action: changeEmail
        }
    }

    const [validationErrors, setValidationErrors] = useState({
        password: null,
        phone: null,
        email: null,
    });

    const handleInputChange = (e, param) => {
        const { name, value } = e.target;

        params[param].setStateData({
            ...params[param].stateData,
            [name]: value,
        });

        setValidationErrors({
            ...validationErrors,
            [name]: null,
        });
    }

    const setError = (name, message) => {
        setValidationErrors({
            ...validationErrors,
            [name]: message,
        });
        return false;
    }

    const validateForm = async (param) => {
      try {
        await params[param].validationSchema.validate(params[param].stateData, { abortEarly: false });
        return true;
      } catch (errors) {
        console.log(errors.inner)
        let error = errors.inner[1] || errors.inner[0];
        return setError(param, error.message);
      }
    }

    const handleChange = async (param) => {
        dispatch(discardChanged());
        setUpdatingParam(param);
        const formValid = await validateForm(param);
        if (formValid === true) {
            setValidationErrors({
                ...validationErrors,
                [param]: null,
            });
            dispatch(params[param].action(params[param].stateData));
        }
    }

    const handleDeleteClick = () => {
        setDeletePopupOpen(true);
    }

    const handleConfirmDelete = () => {
        dispatch(deleteAccount());
        setDeletePopupOpen(false);
        navigate('/');
    }

    const handleCancelDelete = () => {
        setDeletePopupOpen(false);
    }

    // console.log('isAuthenticated', isAuthenticated)
    // console.log('userLoading', userLoading)
    useEffect(() => {
        if (!userLoading && !isAuthenticated) {
          navigate('/');
        }
    }, [userLoading, isAuthenticated, navigate]);

    return (
        <div className="account container">
            <Breadcrumbs link="/" />
            <div className="account-forms d-flex flex-column align-items-center">
                <div className="account-form d-flex flex-column align-items-center">
                    <div className="account-form-title text-center">Зміна паролю</div>
                    <div className="account-form-fields d-flex flex-column">                        
                        {params.password.fields.map((item, index) => {
                            const { label, name, value } = item;
                            return (
                                <div className="account-form-field d-flex" key={index}>
                                    <div className="account-form-field-label">{label}</div>
                                    <div className="account-form-field-input d-flex justify-content-between align-items-center">
                                        <input className="w-100 h-100" type="password" name={name} value={value} placeholder={label} onChange={(e) => handleInputChange(e, 'password')} />
                                        <img src={pencil} alt="" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {updatingParam === 'password' && (apiError || validationErrors.password) && <div className="auth-message text-red">{apiError || validationErrors.password}</div>}
                    {passwordChanged && <div className="auth-message">Пароль успішно змінено</div>}
                    <button className="btn-small btn-black" onClick={() => handleChange('password')}>
                        <span>Зберегти</span>
                    </button>
                </div>
                <div className="account-forms-divider"></div>
                <div className="account-form d-flex flex-column align-items-center">
                    <div className="account-form-title text-center">Зміна номеру телефону</div>
                    <div className="account-form-fields d-flex flex-column">
                        <div className="account-form-field d-flex">
                            <div className="account-form-field-label">{params.phone.field.label}</div>
                            <div className="account-form-field-input d-flex justify-content-between align-items-center">
                                <InputMask
                                    className="w-100 h-100"
                                    type="text"
                                    name="phone"
                                    mask={PHONE_MASK}
                                    value={params.phone.field.value}
                                    placeholder={params.phone.field.label}
                                    onChange={(e) => handleInputChange(e, 'phone')}
                                />
                                <img src={pencil} alt="" />
                            </div>
                        </div>
                    </div>
                    {updatingParam === 'phone' && (apiError || validationErrors.phone) && <div className="account-form-message text-red">{apiError || validationErrors.phone}</div>}
                    {phoneChanged && <div className="account-form-message">Номер телефону успішно змінено</div>}
                    <button className="btn-small btn-black" onClick={() => handleChange('phone')}>
                        <span>Зберегти</span>
                    </button>
                </div>
                <div className="account-forms-divider"></div>
                <div className="account-form d-flex flex-column align-items-center">
                    <div className="account-form-title text-center">Зміна Email</div>
                    <div className="account-form-fields d-flex flex-column">
                        <div className="account-form-field d-flex">
                            <div className="account-form-field-label">{params.email.field.label}</div>
                            <div className="account-form-field-input d-flex justify-content-between align-items-center">
                                <input className="w-100 h-100" type="email" name="email" value={params.email.field.value} placeholder={params.email.field.label} onChange={(e) => handleInputChange(e, 'email')} />
                                <img src={pencil} alt="" />
                            </div>
                        </div>
                    </div>
                    {updatingParam === 'email' && (apiError || validationErrors.email) && <div className="account-form-message text-red">{apiError || validationErrors.email}</div>}
                    {emailChanged && <div className="account-form-message">Email успішно змінено</div>}
                    <button className="btn-small btn-black" onClick={() => handleChange('email')}>
                        <span>Зберегти</span>
                    </button>
                </div>
            </div>
            <div className="account-delete d-flex justify-content-center">
                <button className="btn-medium btn-black" onClick={() => handleDeleteClick()}>
                    <img className="btn-icon-trash" src={trash} alt="" />
                    <span>Видалити сторінку</span>
                </button>
            </div>
            <DeleteAccount
                isOpen={deletePopupOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    )
}

export default Account;
