import api from './api';

class NotificationService
{
	async getMyNotifications(token) {
		try {
			const response = await api.get(`/notification/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[NotificationService][getMyNotifications][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async readNotification(payload, token) {
		try {
			const response = await api.post('/notification/read', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[NotificationService][readNotification][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new NotificationService();
