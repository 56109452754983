import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import arrow from '../../assets/icons/arrow-left.svg';

const Breadcrumbs = ({ link }) => {
    const navigate = useNavigate();
    
    const goBack = () => {
        navigate(-1);
    }

    return (
        <Link className="breadcrumbs d-inline-flex align-items-center" onClick={goBack}>
            <img className="breadcrumbs-arrow" src={arrow} alt="" />
            <span className="breadcrumbs-link">Повернутись назад</span>
        </Link>
    )
}

export default Breadcrumbs;
