import React from 'react';
import './styles.scss';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { API_URL } from '../../constants/api';

import userDefault from '../../assets/images/user-default.png';
import chevronLeft from '../../assets/icons/chevron-left.svg';
import chevronRight from '../../assets/icons/chevron-right.svg';

const PrevArrow = ({ onClick }) => {
  return (
    <div
    	className="product-comments-carousel-nav product-comments-carousel-nav-prev absolute"
    	onClick={onClick}
    >
    	<img src={chevronLeft} alt="" />
    </div>
  )
}

const NextArrow = ({ onClick }) => {
  return (
    <div
    	className="product-comments-carousel-nav product-comments-carousel-nav-next absolute"
    	onClick={onClick}
    >
    	<img src={chevronRight} alt="" />
    </div>
  )
}

const CommentItem = ({ comment, index }) => {
	const { author, content } = comment;
	const { firstname, lastname } = author.profile;

  const getImageUrl = (path) => {
    if (path.includes('https://')) return path;
    else return `${API_URL}/${path}`;
  }

	const avatar = author.avatars[0] ? getImageUrl(author.avatars[0].path) : userDefault;
	return (
		<div className="product-comments-carousel-item d-flex align-items-start" key={index}>
    	<div className="product-comments-carousel-item-image">
        	<img src={avatar} alt="" />
      </div>
      <div className="product-comments-carousel-item-content d-flex flex-column align-items-start">
      	<div className="product-comments-carousel-item-author">{`${firstname} ${lastname}`}</div>
      	<div className="product-comments-carousel-item-text">{content}</div>
      </div>
      <div className="product-comments-carousel-item-text-mobile">{content}</div>
    </div>
	)
}

const ProductComments = ({ comments }) => {

  let settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		initialSlide: 0,
		prevArrow: <PrevArrow onClick={Slider.slickPrev} />,
		nextArrow: <NextArrow onClick={Slider.slickNext} />,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	}

	if (comments.length === 1) {
		return <CommentItem comment={comments[0]} index={0} key={0} />
	}

	return (
		<Slider {...settings}>
			{comments.map((comment, index) => {
				return <CommentItem comment={comment} index={index} key={index} />
      })}
	  </Slider>
	)
}

export default ProductComments;
