import StatsService from '../services/stats.service';

export const GET_STATS_REQUEST = 'GET_STATS_REQUEST';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_FAILURE = 'GET_STATS_FAILURE';

export const getStatsRequest = () => ({ type: GET_STATS_REQUEST });
export const getStatsSuccess = (data) => ({ type: GET_STATS_SUCCESS, payload: data });
export const getStatsFailure = (error) => ({ type: GET_STATS_FAILURE, payload: error });

export const getStats = () => async (dispatch) => {
  try {
    dispatch(getStatsRequest());
    const token = localStorage.getItem('token');
    const result = await StatsService.getStats(token);
    console.log(result)
    if (result.success) {
      dispatch(getStatsSuccess(result));
    }
    else {
      dispatch(getStatsFailure(result.message));
    }
  } catch (e) {
    dispatch(getStatsFailure(e.toString()));
  }
}
