import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDialog } from '../../actions/dialog';
import { setProjectSelected } from '../../actions/project';
import * as C from '../../constants/project';
import { addOrderItem, updateOrderItem, updateOrder } from '../../actions/order';
import { preOrderStatuses, mainOrderStatuses, orderStatuses, orderConfirmStatuses } from '../../constants/order';
import { API_URL } from '../../constants/api';
import Breadcrumbs from '../Common/Breadcrumbs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from  "react-datepicker";
import { uk } from 'date-fns/locale/uk';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import noImage from '../../assets/images/product-no-image.png';
import plus from '../../assets/icons/plus-white.svg';
import AddOrderItem from '../Popup/AddOrderItem';
import DialogChat from './DialogChat';

const Dialog = () => {
    registerLocale('uk', uk);

    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const uuid = pathArray.length === 3 ? pathArray[2] : null;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const dialog = useSelector((state) => state.dialog.dialog);

    const [mode, setMode] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                if (uuid) {
                    const resultDialog = await dispatch(getDialog(uuid));
                    if (resultDialog) {
                        if (resultDialog.customerId == authUser.id) {
                            setMode('customer');
                        } else if (resultDialog.ownerId == authUser.id) {
                            setMode('owner');
                        } else {
                            navigate('/404');
                        }
                    } else {
                        navigate('/404');
                    }
                } else {
                    navigate('/');
                }
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [uuid, userLoading, isAuthenticated, authUser]);


    const [mainData, setMainData] = useState({
        // client: '',
        eventType: '',
        guests: '',
        receiveDate: null,
        returnDate: null,
        endDatetime: null,
        location: '',
        status: 'new'
    });

    const [editingParam, setEditingParam] = useState(null);

    const params = {
        /*client: {
            field: { label: C.CLIENT, name: 'client', value: mainData.client, ref: useRef(null) },
            // validationSchema: clientSchema,
            editable: false,
            isEditing: false
        },*/
        eventType: {
            field: { label: C.EVENT_TYPE, name: 'eventType', value: mainData.eventType, ref: useRef(null) },
            // validationSchema: eventTypeSchema,
            editable: false,
            isEditing: false
        },
        guests: {
            field: { label: C.GUESTS, name: 'guests', value: mainData.guests, ref: useRef(null) },
            // validationSchema: guestsSchema,
            editable: false,
            isEditing: false
        },
        receiveDate: {
            field: { label: C.RECEIVE_DATE, name: 'receiveDate', value: mainData.receiveDate, ref: useRef(null) },
            // validationSchema: startDatetimeSchema,
            editable: true,
            isEditing: false
        },
        returnDate: {
            field: { label: C.RETURN_DATE, name: 'returnDate', value: mainData.returnDate, ref: useRef(null) },
            // validationSchema: startDatetimeSchema,
            editable: true,
            isEditing: false
        },
        endDatetime: {
            field: { label: C.END_DATETIME, name: 'endDatetime', value: mainData.endDatetime, ref: useRef(null) },
            // validationSchema: endDatetimeSchema,
            editable: false,
            isEditing: false
        },
        location: {
            field: { label: C.LOCATION, name: 'location', value: mainData.location, ref: useRef(null) },
            // validationSchema: locationSchema,
            editable: false,
            isEditing: false
        },
        status: {
            field: { label: C.STATUS, name: 'status', value: mainData.status, ref: useRef(null) },
            // validationSchema: locationSchema,
            editable: false,
            isEditing: false
        },
    }

    const [customerConfirmation, setCustomerConfirmation] = useState(null);
    const [ownerConfirmation, setOwnerConfirmation] = useState(null);

    const [items, setItems] = useState([]);

    const discountRef = useRef(null);
    const [discount, setDiscount] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        if (dialog) {
            setMainData({     
                // client: dialog.project.client || '',
                eventType: dialog.project.eventType || '',
                guests: dialog.project.guests || '',
                receiveDate: dialog.order.receiveDate ? new Date(dialog.order.receiveDate) : new Date(dialog.project.startDatetime),
                returnDate: dialog.order.returnDate ? new Date(dialog.order.returnDate) : new Date(dialog.project.endDatetime),
                endDatetime: new Intl.DateTimeFormat('uk-UA', {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: 'Europe/Kyiv',
                }).format(new Date(dialog.project.endDatetime)),
                location: dialog.project.location || '',
                status: orderStatuses[dialog.order.status] || '',
            });

            setCustomerConfirmation(dialog.order.customerConfirmation);
            setOwnerConfirmation(dialog.order.ownerConfirmation);
            setItems(dialog.order.items);
            setDiscount(dialog.order.discount)
            setTotalCost(dialog.order.totalCost)
        }
    }, [dialog]);

    const handleReceiveDateChange = (selectedDate) => {
        setMainData({
            ...mainData,
            receiveDate: selectedDate,
        });
    }

    const handleReturnDateChange = (selectedDate) => {
        setMainData({
            ...mainData,
            returnDate: selectedDate,
        });
    }

    const userLink = (user) => {
        const { slug, profile } = user;
        const { firstname, lastname } = profile;
        return (
            <Link to={`/profile/${slug}`} className="dialog-confirmation-data-field-label d-flex align-items-center">{
                `${firstname} ${lastname}`
            }</Link>
        )
    }

    const setOrderConfirmation = async (confirmed) => {
        const orderConfirmation = confirmed ? 'confirmed' : 'canceled';
        await dispatch(updateOrder({ id: dialog.order.id, [`${mode}Confirmation`]: orderConfirmation }));
        if (mode == 'customer') {
            setCustomerConfirmation(orderConfirmation);
            await checkConfirmations(orderConfirmation, dialog.order.ownerConfirmation);
        }
        if (mode == 'owner') {
            setOwnerConfirmation(orderConfirmation);
            await checkConfirmations(dialog.order.customerConfirmation, orderConfirmation);
        }
    }

    const checkConfirmations = async (customerConfirmation, ownerConfirmation) => {
        let newOrderStatus = customerConfirmation == ownerConfirmation ? customerConfirmation : 'pending';
        if (newOrderStatus) {
            await dispatch(updateOrder({ id: dialog.order.id, status: newOrderStatus }));
            setMainData({ ...mainData, status: orderStatuses[newOrderStatus] });
        }
    }

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else return `${API_URL}/${path}`;
    }

    const handleAmountChange = async (e, item) => {
        const newAmount = Number(e.target.value);
        if (!isNaN(newAmount) && newAmount >= 0) {
            const updatedItems = items.map((i) => i.id === item.id ? { ...i, amount: newAmount } : i);
            setItems(updatedItems);
            let newTotalCost = updatedItems.reduce((sum, item) => sum + (item.amount * item.price), 0);
            newTotalCost = Number((newTotalCost - newTotalCost * discount / 100).toFixed(0));
            setTotalCost(newTotalCost)
            await dispatch(updateOrderItem({ id: item.id, amount: newAmount }))
            await dispatch(updateOrder({ id: dialog.order.id, totalCost: newTotalCost }));
        }
    }

    const [addItemPopupOpen, setAddItemPopupOpen] = useState(false);

    const handleAddOrderItemClick = () => {
        setAddItemPopupOpen(true);
    }

    const handleConfirmAddOrderItem = async (data) => {
        console.log(data)
        await dispatch(addOrderItem({ orderId: dialog.order.id, ...data }));
        // dialog.order.items.push(data);
        const newItems = [ ...items, data ]
        setItems(newItems);
        let newTotalCost = newItems.reduce((sum, item) => sum + (Number(item.amount) * Number(item.price)), 0);
        console.log(newTotalCost)
        newTotalCost = Number((newTotalCost - newTotalCost * discount / 100).toFixed(0));
        setTotalCost(newTotalCost)
        setAddItemPopupOpen(false);
    }

    const handleCancelAddOrderItem = () => {
        setAddItemPopupOpen(false);
    }

    const goToCatalog = async (url = '/catalog') => {
        await dispatch(setProjectSelected(dialog.project.id));
        navigate(url);
    }

    const handleDiscountChange = async (e) => {
        const newDiscount = Number(e.target.value);
        if (!isNaN(newDiscount) && newDiscount >= 0 && newDiscount <= 100) {
            let newTotalCost = dialog.order.items.reduce((sum, item) => sum + (item.amount * item.price), 0);
            newTotalCost = Number((newTotalCost - newTotalCost * newDiscount / 100).toFixed(0));
            setDiscount(newDiscount);
            setTotalCost(newTotalCost)
            await dispatch(updateOrder({ id: dialog.order.id, discount: newDiscount, totalCost: newTotalCost }));
        }
    }

	return (<>
        <div className="dialog container">
            <Breadcrumbs />            
            <div className="dialog-body d-flex flex-column">
                <div className="project-data dialog-data w-100 d-flex flex-column align-items-center relative">
                    <div className="project-data-fields w-100 d-flex flex-column">
                        {Object.keys(params).map((param, index) => {
                            const item = params[param];
                            const { field } = item;
                            const { label, name, value, ref } = field;
                            return (
                                <div className={`project-data-field project-data-field-${name} d-flex`} key={index}>
                                    <div className="project-data-field-label d-flex justify-content-center align-items-center text-black">{label}</div>
                                    <div className="project-data-field-input d-flex justify-content-between align-items-center">
                                        {name == 'receiveDate' && 
                                            <DatePicker
                                                className="w-100"
                                                locale="uk"
                                                dateFormat="dd.MM.yyyy"
                                                selected={mainData.receiveDate}
                                                onChange={handleReceiveDateChange}
                                                minDate={new Date()}
                                            />
                                        }
                                        {name == 'returnDate' && 
                                            <DatePicker
                                                className="w-100"
                                                locale="uk"
                                                dateFormat="dd.MM.yyyy"
                                                selected={mainData.returnDate}
                                                onChange={handleReturnDateChange}
                                                minDate={new Date()}
                                            />
                                        }
                                        {name != 'receiveDate' && name != 'returnDate' && 
                                            <span className="w-100">{value}</span>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {dialog && <div className="dialog-confirmation-data">
                        <div className="dialog-confirmation-data-field d-flex">
                            <div className="dialog-confirmation-data-field-label d-flex align-items-center">Клієнт</div>
                            {userLink(dialog.customer)}
                            {customerConfirmation && <div className={`dialog-confirmation-data-field-value d-flex align-items-center text-${orderConfirmStatuses[customerConfirmation].color}`}>
                                {orderConfirmStatuses[customerConfirmation].name}
                            </div>}
                        </div>
                        <div className="dialog-confirmation-data-field d-flex">
                            <div className="dialog-confirmation-data-field-label d-flex align-items-center">Орендар</div>
                            {userLink(dialog.owner)}
                            {ownerConfirmation && <div className={`dialog-confirmation-data-field-value d-flex align-items-center text-${orderConfirmStatuses[ownerConfirmation].color}`}>
                                {orderConfirmStatuses[ownerConfirmation].name}
                            </div>}
                        </div>
                    </div>}
                </div>
                {dialog && <div className="project-order-items w-100 d-flex flex-column align-items-end">
                    <div className="dialog-confirmation-buttons w-100 d-flex justify-content-center align-items-center">
                        <button className="btn-small btn-black" onClick={() => setOrderConfirmation(false)}>
                            <span className="w-100 flex-1">Відхилити</span>
                        </button>
                        <button className="btn-small btn-black" onClick={() => setOrderConfirmation(true)}>
                            <span className="w-100 flex-1">Прийняти</span>
                        </button>                        
                    </div>
                    <Table className="project-order-items-table w-100">
                        <TableHead>
                            <TableRow className="project-order-items-table-row">
                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-num">№</TableCell>
                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-product">Назва</TableCell>
                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-amount">Кількість</TableCell>
                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-price">Ціна, грн</TableCell>
                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-sum">Сума, грн</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, index) => {
                                const { title, amount, price, product } = item;
                                const productImage = product && product.images.length !== 0 ? getImageUrl(product.images[0].path) : noImage;
                                const productLink = product ? `/product/${product.slug}` : null;
                                return (
                                    <TableRow className="project-order-items-table-row" key={index}>
                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-num">{index + 1}</TableCell>
                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-product">
                                            <Link to={productLink} className="d-flex align-items-center">
                                                <div><img src={productImage} /></div>
                                                <div className="w-100 flex-1">{title}</div>
                                            </Link>
                                        </TableCell>
                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-amount">
                                            <input className="w-100 h-100 project-order-discount text-black" type="text" value={amount} onChange={(e) => handleAmountChange(e, item)} />
                                        </TableCell>
                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-price">{price}</TableCell>
                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-sum">{amount * price}</TableCell>
                                    </TableRow>
                                )
                            })}
                            <TableRow className="project-order-items-table-row">
                                <TableCell className="project-order-items-table-cell"></TableCell>
                                <TableCell className="project-order-items-table-cell"></TableCell>
                                <TableCell className="project-order-items-table-cell"></TableCell>
                                <TableCell className="project-order-items-table-cell">Знижка, %</TableCell>
                                <TableCell className="project-order-items-table-cell">
                                    <input className="w-100 h-100 project-order-discount text-black" type="text" name="discount_percent" value={discount || 0} ref={discountRef} onChange={(e) => handleDiscountChange(e)} />
                                </TableCell>
                            </TableRow>
                            <TableRow className="project-order-items-table-row">
                                <TableCell className="project-order-items-table-cell"></TableCell>
                                <TableCell className="project-order-items-table-cell">Всього, грн</TableCell>
                                <TableCell className="project-order-items-table-cell"></TableCell>
                                <TableCell className="project-order-items-table-cell"></TableCell>
                                <TableCell className="project-order-items-table-cell">{totalCost}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <div className="project-order-items-actions w-100 d-flex justify-content-end align-items-center">
                        <button className="btn-medium btn-black" onClick={handleAddOrderItemClick}>
                            <img className="btn-icon-plus" src={plus} alt="" />
                            <span>Додати поле вручну</span>
                        </button>
                        <button className="btn-medium btn-black" onClick={() => goToCatalog(`/catalog/owner/${dialog.owner.id}`)}>
                            <img className="btn-icon-plus" src={plus} alt="" />
                            <span>Додати з каталогу компанії</span>
                        </button>
                    </div>
                    <AddOrderItem
                        isOpen={addItemPopupOpen}
                        onConfirm={handleConfirmAddOrderItem}
                        onCancel={handleCancelAddOrderItem}
                    />
                </div>}
            </div>
        </div>
        {dialog && <DialogChat dialogId={dialog.id} authUser={authUser} />}
    </>);
}

export default Dialog;
