import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStats } from '../../actions/stats';
import './styles.scss';
import Breadcrumbs from '../Common/Breadcrumbs';
import BarChart from './BarChart';

const Stats = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const statsLoading = useSelector((state) => state.stats.loading);
    const projects = useSelector((state) => state.stats.projects);
    const orders = useSelector((state) => state.stats.orders);

    useEffect(() => {
        const fetchData = async () => {
            if (authUser) {
                dispatch(getStats());
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [authUser, userLoading, dispatch]);

    return (
        <div className="stats container">
            <Breadcrumbs link="/" />
            <div className="stats-body w-100 d-flex flex-wrap">
            	<div><BarChart title="Проекти" items={projects} /></div>
            	<div><BarChart title="Замовлення" items={orders} /></div>
            </div>
        </div>
    )
}

export default Stats;
