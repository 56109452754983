import {
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
  GET_STATS_FAILURE,
} from '../actions/stats';

const initialState = {
  loading: true,
  projects: [],
  orders: [],
  error: null,
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
        orders: action.payload.orders,
        error: null
      };
    case GET_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        projects: [],
        orders: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default statsReducer;
