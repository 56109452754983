import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-black.svg';
import easypay from '../../assets/images/payments/easypay.svg';
import visa from '../../assets/images/payments/visa.png';
import mastercard from '../../assets/images/payments/mastercard.svg';
import prostir from '../../assets/images/payments/prostir.png';

const Footer = () => {
    const paymentLogos = [
        { name: 'easypay', image: easypay },
        { name: 'visa', image: visa },
        { name: 'mastercard', image: mastercard },
        { name: 'prostir', image: prostir },
    ];

    const docs = [
        { title: 'Політика конфіденційності', url: '/privacy-policy' },
        { title: 'Політика використання файлів куки', url: '/cookie-policy' },
        { title: 'Умови використання', url: '/terms-of-service' },
        { title: 'Правила використання вмісту', url: '/content-usage-policy' },
    ];

    return (
        <div className="footer w-100 d-flex flex-column align-items-center">
            <Link to="/" className="footer-logo"><img className="w-100" src={logo} alt="" /></Link>
            {/*<div className="footer-email">globaleventmakersupprt@gmail.com</div>*/}
            <div className="footer-contacts"><Link to="/contacts">Контакти</Link></div>
            <div className="footer-payment-logo-list d-flex align-items-center">
                {paymentLogos.map((item, index) => {
                    return (
                        <div className="footer-payment-logo-item" key={index}>
                            <img src={item.image} key={index} />
                        </div>
                    )
                })}
            </div>
            <div className="footer-docs d-flex align-items-center">
                {/*Політика конфіденційності (Privacy Policy); Політика використання файлів куки (Cookie Policy); Умови використання (Terms of Service або Terms and Conditions); Правила використання вмісту (Content Usage Policy)*/}
                {docs.map((doc, index) => <Link to={doc.url} key={index}>{doc.title}</Link>)}
            </div>
        </div>
    )
}

export default Footer;
